import { CardContent } from '@mui/material';
import type { FC } from 'react';
import { LottiePlayer } from '../../components/lottie/player.js';
import { CenterCard } from '../../components/page-layout/center-card.js';
import { PageTitle } from '../../components/page-title/page-title.js';

export const NotFoundPage: FC = () => (
  <PageTitle title="Not Found">
    <CenterCard sx={{ my: 6, height: '100%' }}>
      <CardContent>
        <LottiePlayer
          sx={{ height: 350 }}
          src="https://assets7.lottiefiles.com/private_files/lf30_aiwdh2wk.json"
        />
      </CardContent>
    </CenterCard>
  </PageTitle>
);

export default NotFoundPage;
